import {Component, Injector, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {UIBankService} from '../../../ui/ui-bank.service';
import {UiOptionsModel} from '../../../ui/ui-options.model';
import {UiComponent} from '../../../ui/ui.component';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { InsuredPerson } from 'src/app/model/insured-person.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import {takeUntil} from 'rxjs/operators';
import {PaymentOptions} from '../../../model/payment-options';
import {Notifications} from '../../../utilities/components/notification-messages/notifications.model';
import {PAYMENT_METHODS} from '../../../model/payment-methods.constant';
import {ClaimSearchService} from '../../../services/claim-search.service';
import {OtpBoxComponent} from '../../../otp-box/otp-box.component';

declare let $: any;

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BankAccountComponent extends ClaimsBaseComponent implements OnInit {
    showTotalBox = false;
    bankAccountForm: FormGroup;
    isMotorLodgedBy: string = '';
    isInternetExplorer;
    @ViewChild('PYM03', {static: false, read: UiComponent}) uiBankNameField: UiComponent;
    currentLanguage: string;
    bankListEnglish: any[];
    bankListChinese: any[];
    transactionInfo : TransactionInfo;
    bankSelectImg: string;
    bankSelected: string = "bankcomm";
    bankDescription: string;
    accountNameDirectList: UiOptionsModel[] = [];
    country: string;
    showBranchCodeInstruction: boolean = false;
    showBranchCodeMsg: boolean = true;
    bankService: UIBankService = new UIBankService();
    isFPSAllowed: boolean = false;
    fpsForm: FormGroup;
    paymentOptions: any;
    notifications: Notifications = new Notifications();
    PAYMENT_METHODS = PAYMENT_METHODS;
    otpToken: string = "";
    showOtpError: boolean = false;
    otp: string = "";
    recipient: string = "";
    infoMessage: string = "";
    subInfoMessage: string = "";

    @ViewChild('modal_otp', {static: true}) otpBox: OtpBoxComponent;
    @ViewChild('modal_info', {static: true}) private infoModal: TemplateRef<any>;
    @ViewChild('modal_user_locked', {static: true}) private lockedModal: TemplateRef<any>;
    @ViewChild('FPS_MOBILE_NO', {static: false, read: UiComponent}) uiFpsMobileNo: UiComponent;
    @ViewChild('FPS_EMAIL', {static: false, read: UiComponent}) uiFpsEmail: UiComponent;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private modalService: NgbModal,
                private transactionInfoService : TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
        this.country = this.claim.getCountry();
        if (this.currentLanguage == 'zh') {
            this.translate.getTranslation("en").subscribe(res => {
                this.bankListEnglish = res['bankList'];
            }); 

            this.translate.getTranslation("zh").subscribe(res => {
                this.bankListChinese = res['bankList'];
            });
        }
        this.isFPSAllowed = this.claim.getCountry()?.toUpperCase() === 'HKG' && this.claim.getServiceUnit() !== 'GP' &&
            this.transactionInfo.getTransactionApplicationOrigin() !== TransactionInfo.EBUSINESS &&
            this.claim.getSelectedClaimTypesList().every(claimType => !TransactionInfo.HKG_FPS_NOT_ELIGIBLE.includes(claimType));
    }

    ngOnInit() {

        this.pushGABankAccountView();
        this.currentLanguage = this.translate.currentLang;
        this.populateAccountNameList();

        this.bankAccountForm = this.fb.group(
            {
                accountNameDirect: [UtilitiesService.truncateValue(this.claim.getAccountNameDirect(), 80)],
                bankDirect: [this.claim.getBankDirectDesc()],
                branchCode:  [this.claim.getBranchCode()],
                accountNumberDirect: [this.claim.getAccountNumberDirect()],
                bankSelectImg: [this.bankSelected]
            }
        );

        this.fpsForm = this.fb.group(
            {
                fpsProxyIdType: [this.claim.getProxyIdType()],
                fpsMobileNo: [this.claim.getProxyId()],
                fpsEmail: [this.claim.getProxyId()],
                fpsId: [this.claim.getProxyId()],
                confirmFpsId: [this.claim.getProxyIdType()?.toLowerCase() === 'fps id' ? this.claim.getProxyId() : null]
            }
        );


      // detect if it is an Internet Explorer browser.
      let IEDetected = this.detectIE();
      if(IEDetected === false){
        //console.log("IS NOT BOTH!");
        this.isInternetExplorer = false;
      }
      else if(IEDetected >= 12){
        //console.log("IS EDGE!");
        this.isInternetExplorer = false;
      } else {
        //console.log("IS INTERNET EXPLORER!");
        this.isInternetExplorer = true;
      }

      if (this.isFPSAllowed) {
          this.paymentOptions = PaymentOptions;

          // pre-populate selected option
          let selectedPayment = this.paymentOptions.find(paymentItem => this.claim.getPaymentMethod() == paymentItem.id);
          if (selectedPayment) {
              selectedPayment.selected = true;
          }

          setTimeout(() => {
              this.showSvgPath();
          }, 10);

      } else {
          this.claim.setPaymentMethod(PAYMENT_METHODS.BANK_TRANSFER);
          this.setBankBranchCodeImage();
      }

    }

    ngAfterViewInit() {

        this.bankAccountForm.valueChanges.subscribe(data => {
            this.claim.setAccountNameDirect(super.getValueInComponent('accountNameDirect'));
            this.claim.setBranchCode(this.bankAccountForm.get('branchCode').value);
            this.claim.setAccountNumberDirect(this.bankAccountForm.get('accountNumberDirect').value);
            this.claim.setBankDirect(this.getValueInComponent('bankDirect'));
            this.claim.setBankDirectDesc(this.getTextInComponent('bankDirect'));

            setTimeout(() => {
                this.setBankBranchCodeImage();
            }, 100);

        });

        this.fpsForm.valueChanges.subscribe(data => {
            this.claim.setProxyIdType(super.getValueInComponent('fpsProxyIdType'));
            this.claim.setProxyIdTypeDesc(super.getSelectedRadioText('fpsProxyIdType'));

            if (this.isNotNullOrUndefined(super.getValueInComponent('fpsMobileNo'))) {
                this.claim.setProxyId(super.getValueInComponent('fpsMobileNo'));
            } else if (this.isNotNullOrUndefined(super.getValueInComponent('fpsEmail'))) {
                this.claim.setProxyId(super.getValueInComponent('fpsEmail'));
            } else if (this.isNotNullOrUndefined(super.getValueInComponent('fpsId'))) {
                this.claim.setProxyId(super.getValueInComponent('fpsId'));
            }
        });

        this.fpsForm.get('fpsProxyIdType').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
              this.validateFpsProxy(data);
            });

        this.fpsForm.get('fpsMobileNo').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.claim.setProxyIdVerified(false);
            });

        this.fpsForm.get('fpsEmail').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
               this.claim.setProxyIdVerified(false);
            });
    }

    setBankBranchCodeImage(){
        let selectedBank : string = this.claim.getBankDirect();
        this.showBranchCodeInstruction = false;
        if(this.isNotNullOrUndefinedStr(selectedBank) && this.country !== "mys"){
            let imagePath;
            let bankDesc;
            let ctry = this.country?.toLowerCase();
            if (ctry === "sgp"){
                selectedBank = selectedBank.substr(0, 4) + selectedBank.substr(7, 1).toLowerCase();
            } else if (ctry === "hkg" || ctry === "hgi")  {
                selectedBank = selectedBank.substr(0, 3);
            }
            selectedBank = this.bankService.getBranchInstructionCode(selectedBank, ctry);
            if(this.isNotNullOrUndefinedStr(selectedBank)){
                imagePath = 'home.howToFindBranchCode.branchCodeImage.'+selectedBank;
                bankDesc = 'home.howToFindBranchCode.branchCodeDescription.'+selectedBank;
                this.setBankCodeImage(imagePath, bankDesc);
            }
        }
    }

    setBankCodeImage(imagePath: string, bankDesc: string){
        this.translate.stream(imagePath).subscribe((res:string) => {
            this.bankSelectImg = res;
        });

        this.translate.stream(bankDesc).subscribe((res:string) => {
            this.bankDescription = res;
        });
        this.showBranchCodeInstruction = true;
    }

    showBranchCodeMessage(show: boolean) {
        this.showBranchCodeMsg = show;
    }


    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.isFPSAllowed && !this.isNotNullOrUndefinedStr(this.claim.getPaymentMethod())) {
            this.notifications.showPreamble = false;
            this.notifications.addErrorMessage("pagePaymentAndContact.fps.selectModeOfPayment");
        } else {
            if (this.validateForm()) {
                this.router.navigate(["/claimform/paymentAndContact/provideContactInfo"], {
                    relativeTo: this.activatedRoute
                });
            }
        }

    }

    back() {
        if(this.transactionInfo.isMotorProduct() && this.transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_MVA_WINDSCREEN'){
            this.sideMenuService.emitProgress(-1); 
            this.router.navigate(["/claimform/windscreen/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        } else {
            let currentSelectedClaimType = this.transactionInfo.getCurrentClaimType();
            let url = '/claimform/anotherInsurance';
            if (this.claim.getCountry().toLowerCase() === 'sgp' && this.transactionInfo.isAccidentProduct()) {
                this.sideMenuService.emitProgress(-1); 
                if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] === currentSelectedClaimType) {
                    url = '/claimform/accidentalMedicalExpenses/supportDoc';
                } else if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS] === currentSelectedClaimType) {
                    url = '/claimform/paOthers/supportDoc';
                }
            }
            this.router.navigate([url], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        if (this.claim.getPaymentMethod() === PAYMENT_METHODS.FASTER_PAYMENT_SYSTEM) {

            if (!this.claim.getProxyIdVerified()) {
                if (this.claim.getProxyIdType()?.toLowerCase() == 'email' && this.fpsForm.get('fpsEmail').value) {
                    this.uiFpsEmail.error("notVerified");
                    throw new Error("Email not verified: " + this.fpsForm.get('fpsEmail')?.value);
                }
                if (this.claim.getProxyIdType()?.toLowerCase() == 'mobile number' && this.fpsForm.get('fpsMobileNo').value) {
                    this.uiFpsMobileNo.error("notVerified");
                    throw new Error("Mobile not verified: " + this.fpsForm.get('fpsMobileNo')?.value);
                }
            }

            return super.validateForm(this.fpsForm);
        } else if (this.claim.getPaymentMethod() === PAYMENT_METHODS.BANK_TRANSFER && this.claim.getServiceUnit() != "GP") {
            return super.validateForm(this.bankAccountForm) && this.validateBankInput();
        }
        return true;
    }

    validateBankInput() : boolean {

        let isValidBank: boolean = false;

        let bankNameInput =  this.getValueInComponent('bankDirect');
        let bankNameInputDesc = super.getTextInComponent('bankDirect');

        // The regex pattern must be removed along with the characters that comes before it because this is the part where the polyfill
        //activates when searching for the substring on IE.
        var regexPattern = /.*\#\#\#\[P0LYFlLLed\]\#\#\#/;

        //console.log('BankNameInput: '+bankNameInput);
        if (this.isNotNullOrUndefinedStr(bankNameInput)) {
           bankNameInput = bankNameInput.replace(regexPattern, '');
           this.claim.setBankDirect(bankNameInput);
         }

        if (this.isNotNullOrUndefinedStr(bankNameInputDesc)) {
            bankNameInputDesc = bankNameInputDesc.replace(regexPattern, '');
            this.claim.setBankDirectDesc(bankNameInputDesc);
        }

        //validate bank name from the drodpwon
        let bankOptions: UiOptionsModel[];
        if (this.country?.toLowerCase() === "sgp") {
            bankOptions = this.bankService.getBankOptionsSgp();
        } else if (this.country?.toLowerCase() === "mys") {
            bankOptions = this.bankService.getBankOptionsMys();
        } else {
            bankOptions = this.bankService.getBankOptions();
        }

        for (var i = 0; i < bankOptions.length;  i++) {
            let labelText =  this.translate.instant(bankOptions[i]['label']);
            let bankOptionText = labelText;
            if (!UtilitiesService.isNullOrUndefined(bankNameInput) && bankOptionText.toLowerCase() == bankNameInput.toLowerCase()) {
                isValidBank = true;
            }
        }

        if (!isValidBank) {
            this.uiBankNameField.error("invalidBank");
            //throw new Error("Error Bank Name Not Found in the list");
        } else{
            const self = this;
            if(self.currentLanguage == 'zh'){
                for (var prop in self.bankListChinese) {
                    if (self.bankListEnglish.hasOwnProperty(prop)) {
                    if(self.claim.getBankDirect() == self.bankListChinese[prop]){
                        self.claim.setBankDirect(self.bankListEnglish[prop].toString());
                        self.claim.setBankDirectDesc(self.claim.getBankDirectDesc());
                    }
                    }
                }

            } else if(self.currentLanguage == 'en'){
                self.claim.setBankDirect(self.claim.getBankDirect());
                self.claim.setBankDirectDesc(self.claim.getBankDirectDesc());
            }
        }

        return isValidBank;
    }

    goToCheque() {
        this.pushGASwitchCheque();
        this.router.navigate(["/claimform/paymentAndContact/sendCheque"], {
            relativeTo: this.activatedRoute
        });
    }

    goToOverseasBank() {
        this.pushGASwitchOverseasBank();
        this.router.navigate(["/claimform/paymentAndContact/payOverseasBank"], {
            relativeTo: this.activatedRoute
        });
    }

    //START - Google Analytics
    pushGABankAccountView() {
        let gaStepNum = this.getGaStep();
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Payment - Bank Account Details',
            'vPath': '/payment-bank-account-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': gaStepNum, 'option': 'Bank'} 	//Step7-Payment
                }
            }
        });
    }

    getGaStep(): number {
        if (this.transactionInfo.isGenericClaim()) {
            return 5;
        } else if (this.transactionInfo.isHomeProduct()) {
            return 6;
        } else if (this.transactionInfo.isAccidentProduct()) {
           return !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes() ?  7 : 8;
        }
        return 7;
    }

    pushGABankAccountFill() {
        (<any>window).dataLayer.push({'event': 'editprefill'});
    }

    pushGASwitchCheque() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtocheque'});
    }

    pushGASwitchOverseasBank() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtooverseasbank'});
    }

    //END - Google Analytics

    open(content, name) {
        this.modalService.open(content, {size: 'lg'});
    }

    detectIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    applyP400Format(event: any, fieldName: string) {
        let newStr = UtilitiesService.p400Format(event.target.value);
        event.target.value = UtilitiesService.p400Format(event.target.value);

        if(fieldName == 'accountNameDirect'){
            this.claim.setAccountNameDirect(newStr);
            this.bankAccountForm.get(fieldName).setValue(newStr);
        }
    }
      
    populateAccountNameList() {
        if (this.transactionInfo.isMotorProduct()) {
            this.isMotorLodgedBy =  this.claim.getClaimMotor().getIsLodgeByPolicyHolder() ? "customer" : "repairer";
        } else if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelProduct() ||
                this.transactionInfo.isGenericClaim()) {
           let policyholder = UtilitiesService.truncateValue(this.claim.getPolicyHolderName(), 80);
           const policyHolderOption = new UiOptionsModel(policyholder, policyholder);
           if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelPersonal()) {
                this.accountNameDirectList.push(policyHolderOption);
           }
           if (this.transactionInfo.isTravelProduct()) {
                if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
                    this.accountNameDirectList.push(policyHolderOption);
                } else {
                    let storedInsuredPersonLists: InsuredPerson[] = this.transactionInfo.getInsuredPersonsList();
                    if (!UtilitiesService.isNullOrUndefined(storedInsuredPersonLists)  && storedInsuredPersonLists.length > 0) {
                        for (var i = 0; i < storedInsuredPersonLists.length; i++) {
                            let insuredPerson = storedInsuredPersonLists[i].getInsuredName();
                            if (storedInsuredPersonLists.find(a => a.valueOf() === insuredPerson) == undefined) {
                                insuredPerson =  UtilitiesService.truncateValue(insuredPerson, 80);
                                let insuredPersonOption = new UiOptionsModel(insuredPerson, insuredPerson);
                                this.accountNameDirectList.push(insuredPersonOption);
                            }
                        }
                    }
                }
            }

           }
        }

    validateFpsProxy(data) {
        this.fpsForm.get('fpsMobileNo').reset();
        this.fpsForm.get('fpsMobileNo').disable({onlySelf: false, emitEvent: false});
        this.fpsForm.get('fpsEmail').reset();
        this.fpsForm.get('fpsEmail').disable({onlySelf: false, emitEvent: false});
        this.fpsForm.get('fpsId').reset();
        this.fpsForm.get('fpsId').disable({onlySelf: false, emitEvent: false});
        this.fpsForm.get('confirmFpsId').reset();
        this.fpsForm.get('confirmFpsId').disable({onlySelf: false, emitEvent: false});
        this.claim.setProxyIdVerified(false);

        if (data?.toLowerCase() == 'mobile number') {
            this.fpsForm.get('fpsMobileNo').enable({onlySelf: false, emitEvent: false});
            this.claim.setProxyCountryCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        } else if (data?.toLowerCase() == 'email') {
            this.fpsForm.get('fpsEmail').enable({onlySelf: false, emitEvent: false});
            this.claim.setProxyCountryCode("");
        } else if (data?.toLowerCase() == 'fps id') {
            this.fpsForm.get('fpsId').enable({onlySelf: false, emitEvent: false});
            this.fpsForm.get('confirmFpsId').enable({onlySelf: false, emitEvent: false});
            this.claim.setProxyCountryCode("");
        }
    }

    toggleActive(paymentItem) {
        let self = this;
        this.paymentOptions.forEach(function (type) {
            if (type.id == paymentItem.id) {
                type.selected = !paymentItem.selected;
                self.claim.setPaymentMethod(type.id);
                self.toggleForms(type.id == "FPS" ? self.fpsForm : self.bankAccountForm, true);
                self.notifications.clearMessages();

                if (type.id == "FPS") {
                    self.toggleForms(self.fpsForm, true);
                } else {
                    self.toggleForms(self.bankAccountForm, true);
                    if (!self.transactionInfo.isEmployeeCompensationProduct()) {
                        let defaultAccountName = self.isNotNullOrUndefinedStr(self.claim.getClaimantName()) ? self.claim.getClaimantName() :
                            self.claim.getPolicyHolderName();
                        let accountNameDirectFormControl = self.bankAccountForm.get('accountNameDirect') as FormControl;
                        accountNameDirectFormControl.setValue(defaultAccountName);
                }
                }
            } else {
                type.selected = false;
                self.toggleForms(type.id == "FPS" ? self.fpsForm : self.bankAccountForm, false);
            }
        });

    }

    toggleForms(form: FormGroup, show: boolean) {
        if(show){
            form.enable({onlySelf: false, emitEvent: false});
        } else {
            form.reset();
            form.disable({onlySelf: false, emitEvent: false});
        }
    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            let $img = $(this);
            let imgID = $img.attr('id');
            let imgClass = $img.attr('class');
            let imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                let $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    sendOtp() {
        let validate = super.validateForm(this.fpsForm);
        this.showOtpError = false;
        this.infoMessage = '';
        this.subInfoMessage = '';
        this.claim.setProxyIdVerified(false);

        if (super.validateForm(this.fpsForm)) {
            let channel = this.claim.getProxyIdType()?.toLowerCase();
            this.recipient = this.claim.getProxyCountryCode() ? this.claim.getProxyCountryCode() + " " + this.claim.getProxyId() :
                this.claim.getProxyId();

            let recipient = this.recipient;
            if (channel === 'mobile number') {
                recipient = UtilitiesService.stripNonNumeric(recipient);
}

            this.injector.get(ClaimSearchService).sendOtp(this.transactionInfo.getIntegrationToken(), channel, recipient)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (result: any) => {
                        if (result?.status == 'SUCCESS') {
                            if (result.token) {
                                this.otpToken = result.token;
                                this.otpBox.open();
                            } else {
                                this.openInfoModal(result);
                            }
                        } else {
                            if (result?.status == 'LOCKED') {
                                this.openInfoModal(this.claim.getProxyIdType());
                            } else {
                                this.showOtpError = true;
                            }
                        }
                    },
                    error: (error: any) => {
                        this.openInfoModal(error?.toString());
                    }
                });
        }

    }

    verifyOtp(otp: string) {
        this.infoMessage = '';
        this.subInfoMessage = '';
        this.claim.setProxyIdVerified(false);

        this.injector.get(ClaimSearchService).verifyOtp(this.otpToken, otp, this.claim.getProxyIdType()?.toLowerCase())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result?.status == 'SUCCESS') {
                        this.otpBox.dismiss();
                        this.claim.setProxyIdVerified(true);
                    } else {
                        if (result?.status == 'LOCKED') {
                            this.modalService.dismissAll();
                            this.openInfoModal(this.claim.getProxyIdType());
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: (error: any) => {
                    this.openInfoModal(error?.toString());
                }
            });
    }

    openInfoModal(type: string) {
        if (type?.toLowerCase() === 'email') {
            this.infoMessage = "motorClaim.homePage.emailAddressLocked";
            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
        } else if (type?.toLowerCase() === 'mobile number') {
            this.infoMessage = "pagePaymentAndContact.fps.mobileLocked";
            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
        } else {
            this.infoMessage = "registration.genericError";
            this.subInfoMessage  = "pagePaymentAndContact.fps.unableToVerify";
        }

        this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
    }

}
