import {AfterViewInit, Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SideMenuService} from "../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TransactionInfoService} from "../../services/transaction-info.service";
import {ClaimsBaseComponent} from "../claim-base.component";
import {Notifications} from "../../utilities/components/notification-messages/notifications.model";
import {InsuredPerson} from "../../model/insured-person.model";
import {Claim} from "src/app/model/claim.model";
import {TransactionInfo} from "../../model/transaction-info.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Claimant} from '../../model/claimant.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClaimTypeItemHelperService} from '../../services/claim-type-item-helper.service';
import {UtilitiesService} from '../../utilities/utilities.service';
import {takeUntil} from 'rxjs/operators';
import {ClaimTypes} from '../../model/claim-type.model';
import {CoverDetails} from '../../model/cover-details.model';
import {ClaimTypeItem} from '../../model/claim-type-item.model';
import {PlanHelperService} from '../../services/plan-helper.service';
import {PolicyVerifyForm} from "../../model/policy-verify-form.model";
import {PolicyAuthJson} from "../../model/policy.auth-model";
import {DateService} from "../../ui/ui-datepicker/date.service";
import {IntegrationService} from "../../services/integrationservice";
import {ClaimTypeItemService} from "../../services/claim-type-item-service";

declare var $: any;

@Component({
    selector: 'app-select-insured',
    templateUrl: './select-insured.component.html',
    styleUrls: ['./select-insured.component.css']
})
export class SelectInsuredComponent extends ClaimsBaseComponent  implements OnInit, AfterViewInit {
    public showMoreContent = false;
    public insuredPersonList: InsuredPerson[] = [];
    public selectedPerson: InsuredPerson;
    showTotalBox = false;
    claim: Claim;
    notifications: Notifications;
    otherInsuredPersonForm: FormGroup;
    choosedPersonId: number;
    transactionInfo: TransactionInfo;
    @ViewChild('selected_insured_change', {static: true}) changeInsuredModal: TemplateRef<any>;
    changeInsuredModalRef: any;
    currentClaimantName: string = "";
    otherIdType: string = "hkid";
    showAdditionalClaimantMessage: boolean = false;
    isQnectOrCustomerPortalMILDMH = false;
    otherForm: FormGroup;

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private injector : Injector) {

        super(injector);
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.insuredPersonList  = this.transactionInfo.getInsuredPersonsList();
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        this.isQnectOrCustomerPortalMILDMH = this.transactionInfo.getIsTransactionOrigin() && this.claim.isMILDMHProductPlan();
    }

    ngOnInit() {

        //dataLayer for GA
        this.pushGASelectInsuredView();

        //form group for other insured person
        this.otherInsuredPersonForm = this.fb.group(
            {
                otherPersonDOB: [],
                otherPersonName: [],
                otherPersonHKID: [],
                otherPersonIdType: [this.otherIdType]
            }
        );

        if (this.transactionInfo.isDomesticHelperProduct()) {
            if (this.isQnectOrCustomerPortalMILDMH) {
                this.otherForm = this.fb.group(
                    {
                        dateOfLoss: [this.claim.getDateOfLoss()]
                    }
                );
            }
            this.insuredPersonList  = this.checkDuplicateInsuredForMultiRisk(this.insuredPersonList);
                if (this.insuredPersonList.length == 0) {
                    this.showContent();  // show other fields if there's no helper
                }
        }

        if (this.transactionInfo.isTravelProduct()) {
            if (!UtilitiesService.isNullOrUndefined(this.insuredPersonList) && this.insuredPersonList.length > 1) {
                this.showAdditionalClaimantMessage = true;
            } else {
                this.showAdditionalClaimantMessage = false;
            }
            if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
                this.showMoreContent = true;
            }
        }
        
        if (this.transactionInfo.isGroupAccidentProduct()) {
            this.showMoreContent = true;
            this.showAdditionalClaimantMessage = false;
        }

         // check if has selected insured person already
        if(!UtilitiesService.isNullOrUndefined(this.claim.getClaimantName())){
            let claimantName: string = this.claim.getClaimantName();
            let claimantIdentifier = this.claim.getClaimantIdentifier();
            this.currentClaimantName = claimantName;
            for (var i =0 ; i < this.insuredPersonList.length ; i++) {
                let insuredIdentifier = UtilitiesService.createClaimantIdentifier(this.insuredPersonList[i]);
                let insuredName = this.insuredPersonList[i].getInsuredName();
                if (claimantName == insuredName && claimantIdentifier == insuredIdentifier) {
                    this.selectedPerson = this.insuredPersonList[i];
                    this.choosedPersonId = i;
                }
            }
        }

        if ((this.transactionInfo.isTravelCorporatePolicyHolder() || this.transactionInfo.isGroupAccidentProduct()) && !UtilitiesService.isNullOrUndefined(this.selectedPerson)) {
            this.otherInsuredPersonForm = this.fb.group(
                {
                    otherPersonName: [this.selectedPerson.getInsuredName()],
                    otherPersonDOB: [new Date(this.selectedPerson.getInsuredDateOfBirth())],
                    otherPersonHKID: [this.selectedPerson.getInsuredPersonNationalIdNumber()],
                    otherPersonIdType: [this.selectedPerson.getInsuredPersonIdType()]
                }
            );
        }
    }

    ngAfterViewInit() {
        let self = this;
        setTimeout(() => {self.setIdTypeSelected(self.otherInsuredPersonForm.get('otherPersonIdType').value)},10);
        this.otherInsuredPersonForm.get('otherPersonIdType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.setIdTypeSelected(data);
        });

        this.otherForm?.get('dateOfLoss').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.claim.setDateOfLoss(super.getValueInComponent('dateOfLoss'));
        });
    }

    setIdTypeSelected(value) {
        if (!UtilitiesService.isNullOrUndefined(value) && value) {
            this.otherIdType = value;
        } else {
            this.otherIdType = "hkid";
        }
    }

    showContent() {
        this.pushGASelectInsuredOnClick();
        this.showMoreContent = !this.showMoreContent;
        if (this.showMoreContent && this.transactionInfo.isDomesticHelperProduct()) {
            let idTypeValue = this.otherInsuredPersonForm.get('otherPersonIdType').value;
            if (UtilitiesService.isNullOrUndefined(idTypeValue) || idTypeValue == '') {
                this.otherInsuredPersonForm.get('otherPersonIdType').setValue("hkid");
            }
        }
    }

   checkDuplicateInsuredForMultiRisk(insuredPersonList: InsuredPerson[]) : InsuredPerson[]{
        let insuredPersonMultiRiskList: InsuredPerson[] = [];
       let risksCount = this.transactionInfo.getNumberOfRisks();

       for(var i=1; i <= risksCount; i++) {
           let insuredPersonRisk =  insuredPersonList.filter(insuredPerson => insuredPerson.getRiskNumber() == i);
           insuredPersonRisk.forEach(function (insuredPersonInfo) {
               let insuredPerson: InsuredPerson = insuredPersonMultiRiskList.find(x => x.getInsuredName() == insuredPersonInfo.getInsuredName() &&
                       x.getInsuredPersonNationalIdNumber() == insuredPersonInfo.getInsuredPersonNationalIdNumber());
               if (UtilitiesService.isNullOrUndefined(insuredPerson)) {
                   insuredPersonMultiRiskList.push(insuredPersonInfo);
               }
           });
       }

       // get other insured person if any
       this.getOtherInsured(insuredPersonMultiRiskList, insuredPersonList);

       return insuredPersonMultiRiskList;
   }

   getOtherInsured(insuredPersonList, defaultInsuredPersonList) {
        // other insured does not contain any risk info or number
       let otherInsuredPersonList =  defaultInsuredPersonList.filter(insuredPerson => UtilitiesService.isNullOrUndefined(insuredPerson.getRiskNumber()));
       otherInsuredPersonList.forEach(function (insuredPersonInfo) {
           //list of insured helper to display
           let insuredPerson: InsuredPerson = insuredPersonList.find(x => x.getInsuredName() == insuredPersonInfo.getInsuredName() &&
               x.getInsuredPersonNationalIdNumber() == insuredPersonInfo.getInsuredPersonNationalIdNumber());
           if (UtilitiesService.isNullOrUndefined(insuredPerson)) {
               insuredPersonList.push(insuredPersonInfo);
           }
       });
    }

    choosePerson(index, personDetails: InsuredPerson) {
        this.showMoreContent = false;
        this.choosedPersonId = index;
        this.selectedPerson = personDetails;
    }

    goToNext() {
        this.notifications.clearMessages();

        if (this.isQnectOrCustomerPortalMILDMH) {
           this.handleQnectOrCustomerPortalMILDMH();
        } else {
            if (!UtilitiesService.isNullOrUndefined(this.selectedPerson)) {
                this.handleValidSelectedPerson();
            } else {
                this.handleMissingSelectedPerson();
            }
        }
    }

    private handleValidSelectedPerson(): void {
        if (this.transactionInfo.isTravelProduct()) {
            if (this.claim.getClaimantList().length > 0) {
                let selectedClaimant = this.claim.getClaimantName();
                if (!UtilitiesService.isNullOrUndefined(selectedClaimant) && selectedClaimant !== this.selectedPerson.getInsuredName()) {
                    this.showChangeInsuredModal();
                } else {
                    this.handleTravelCorporatePolicy();
                }
            } else {
                this.handleTravelCorporatePolicy();
            }
        } else if (this.isAccidentOrGroupAccident()) {
            this.buildClaimTypesForInsured(this.selectedPerson);
            this.goToSelectClaim(this.selectedPerson);
        } else {
            this.goToSelectClaim(this.selectedPerson);
        }
    }

    private handleMissingSelectedPerson(): void {
        if (this.transactionInfo.isTravelCorporatePolicyHolder() || this.transactionInfo.isGroupAccidentProduct()) {
            this.addInsuredPerson();
            if (!UtilitiesService.isNullOrUndefined(this.selectedPerson)) {
                if (this.transactionInfo.isGroupAccidentProduct()) {
                    this.buildClaimTypesForInsured(this.selectedPerson);
                }
                this.goToSelectClaim(this.selectedPerson);
            }
        } else {
            this.handlePersonSelectionError();
        }
    }

    private handleTravelCorporatePolicy(): void {
        if (!this.transactionInfo.isTravelCorporatePolicyHolder() ||
            (this.transactionInfo.isTravelCorporatePolicyHolder() && this.updateInsuredPerson())) {
            this.goToSelectClaim(this.selectedPerson);
        }
    }

    private isAccidentOrGroupAccident(): boolean {
        return (this.transactionInfo.isAccidentProduct() && !this.transactionInfo.isGroupAccidentProduct()) ||
            (this.transactionInfo.isGroupAccidentProduct() && this.updateInsuredPerson());
    }

    private handleQnectOrCustomerPortalMILDMH() {
        if (super.validateForm(this.otherForm) && !UtilitiesService.isNullOrUndefined(this.selectedPerson)) {
            // call verify policy and compare the policy inception date against the mil new arrangement effective date
            let policyVerifyForm: PolicyVerifyForm = new PolicyVerifyForm();
            policyVerifyForm.setCountry(this.claim.getCountry());
            policyVerifyForm.setPolicyNumber(this.claim.getPolicyNumber());
            policyVerifyForm.setPolicyType(this.transactionInfo.getPolicyType());
            policyVerifyForm.setLoggingId(this.transactionInfo.getLoggingId());
            policyVerifyForm.setName(this.claim.getPolicyHolderName());
            policyVerifyForm.setDateOfLoss(this.injector.get(DateService).dateToString(this.claim.getDateOfLoss(), "dd/MM/yyyy"));

            this.injector.get(IntegrationService).verifyPolicyNumber(policyVerifyForm)
                .subscribe({
                    next: (policyAuth: PolicyAuthJson) => {
                        if (!this.isNotNullOrUndefinedStr(policyAuth.getErrorStr()) && this.isNotNullOrUndefined(policyAuth.getInceptionDate())) {
                            let currentArrangement = this.claim.getClaimDomesticHelper().getIsNewArrangement();
                            let isNewArrangement = UtilitiesService.isDateAfterOrEqualTo(policyAuth.getInceptionDate(),
                                new Date(TransactionInfo.DMH_MIL_NEW_ARRANGEMENT_EFFECTIVE_DATE));

                            // update the new arrangement flag if it switches between the old and new, and reset the clinical expense details
                            if (currentArrangement !== isNewArrangement) {
                                this.claim.getClaimDomesticHelper().setIsNewArrangement(isNewArrangement);
                                // re-get the claim type list
                                let claimTypeItemList = this.injector.get(ClaimTypeItemService).getClaimTypeList(this.claim.getCountry(), this.transactionInfo,
                                    this.claim.getClaimTravel()?.getGBAPlanType());
                                this.transactionInfo.setClaimTypeItemList(claimTypeItemList);
                                // remove clinical expense claim type
                                if (this.claim.getSelectedClaimTypesList().indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]) !== -1) {
                                    this.claimTypeItemHelperService.deleteClaimType(0);
                                }
                            }

                            this.goToSelectClaim(this.selectedPerson);
                        }
                    },
                    error: (err: any) => {
                        console.error('handleQnectOrCustomerPortalMILDMH verifyPolicyNumber error', err);
                        this.notifications = new Notifications();
                        this.notifications.showPreamble = false;
                        let errorMessage = "error_messages.notificationsErrMsg.serverErrVerifyPolicy";
                        this.notifications.addErrorMessage(errorMessage);
                    }
                });
        } else {
            this.handlePersonSelectionError();
        }
    }

    private handlePersonSelectionError(): void {
        if (UtilitiesService.isNullOrUndefined(this.selectedPerson)) {
            this.notifications = new Notifications();
            this.notifications.showPreamble = false;
            let errorMessage = "error_messages.notificationsErrMsg.insuredPerson";
            this.notifications.addErrorMessage(errorMessage);
        }
    }

    goToSelectClaim(selectedPerson: InsuredPerson) {
        // populate claimant details
        let insuredName = selectedPerson.getInsuredName();
        let premiumClass = selectedPerson.getPremiumClass();
        let policyHolderName = this.claim.getPolicyHolderName();

        this.claim.setClaimantName(insuredName);
        this.claim.setClaimantIdNumber(selectedPerson.getInsuredPersonNationalIdNumber());

        if (premiumClass && premiumClass != '') {
            this.claim.setPremiumClass(premiumClass);
        }

        if (!UtilitiesService.isNullOrUndefined(selectedPerson.getInsuredDateOfBirth())) {
            this.claim.setClaimantDateOfBirth(selectedPerson.getInsuredDateOfBirth());
        } else {
            this.claim.setClaimantDateOfBirth(null);
        }

        if (this.claim.getContactName() == null || this.claim.getContactName() == '' || this.currentClaimantName != insuredName) {
            if(this.transactionInfo.isDomesticHelperProduct() && !UtilitiesService.isNullOrUndefined(policyHolderName) ||
            this.transactionInfo.isTravelCorporatePolicyHolder() || this.transactionInfo.isGroupAccidentProduct()) {
                this.claim.setContactName(policyHolderName);
            } else {
                this.claim.setContactName(insuredName);
            }
        }

        if (this.claim.getPaymentClaimantName() == null || this.claim.getPaymentClaimantName() == '' || this.currentClaimantName != insuredName) {
            if(this.transactionInfo.isDomesticHelperProduct() && !UtilitiesService.isNullOrUndefined(policyHolderName) ||
                this.transactionInfo.isTravelCorporatePolicyHolder() || this.transactionInfo.isGroupAccidentProduct()) {
                this.claim.setPaymentClaimantName(policyHolderName);
            } else {
                this.claim.setPaymentClaimantName(insuredName);
            }
        }

        if (this.claim.getAccountNameDirect() == null || this.claim.getAccountNameDirect() == '' || this.currentClaimantName != insuredName) {
            if(this.transactionInfo.isDomesticHelperProduct() && !UtilitiesService.isNullOrUndefined(policyHolderName) ||
                this.transactionInfo.isTravelCorporatePolicyHolder() || this.transactionInfo.isGroupAccidentProduct()) {
                this.claim.setAccountNameDirect(policyHolderName);
            } else {
                this.claim.setAccountNameDirect(insuredName);
            }
        }

        if (!this.transactionInfo.isDomesticHelperProduct()) {
            this.sideMenuService.emitSelectInsured(this.selectedPerson.getInsuredName());
            if (this.transactionInfo.isTravelProduct()) {
                if (!UtilitiesService.isNullOrUndefined(selectedPerson.getRiskNumber())) {
                    this.claim.setRiskNumber(selectedPerson.getRiskNumber());
                } else {
                    // default to risk 1 if other insured
                    this.claim.setRiskNumber(1);
                }
            }
            if (this.claim.getProduct() === "PAN" && this.claim.getCountry()?.toUpperCase() === "SGP") {
                if (UtilitiesService.isNullOrUndefined(selectedPerson.getRiskNumber())) {
                    // create claim notification for SGP PA if newly created
                    this.claim.setRiskNumber(0); 
                }
            }
        }

        // create claimant identifier
        let claimantIdentifier = UtilitiesService.createClaimantIdentifier(selectedPerson);
        this.claim.setClaimantIdentifier(claimantIdentifier);

        // navigate to next screen
        this.sideMenuService.emitComplete('finishSelectInsured');
        this.sideMenuService.emitProgress(1);

        let url = '/claimform/claimDetail';
        if (this.transactionInfo.isAccidentProduct()) {
            url = this.claim.isHKGorHGI() ? '/claimform/paDetailsOfAccident' : '/claimform/paAccidentDetails';
        }
        
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });

    }

    buildClaimTypesForInsured(selectedPerson: InsuredPerson) {
        let product = this.claim.getProduct();
        let claimTypesList = this.getDefaultClaimTypes(this.transactionInfo.getClaimTypeItemList());
        let insuredCoverDetails = this.getInsuredCoverDetails(selectedPerson);
        let prevClaimTypesList = this.transactionInfo.getClaimTypeItemList().map(y => Object.assign(new ClaimTypeItem(), y));
        let self = this;

        if (this.claim.getCountry()?.toUpperCase() == "SGP") {
            // SGP check catalogue code and cover code if it is eligible for AME and Others claim type
            if (["PANPSC", "PANPST"].indexOf(this.claim.getCatalogueCode()) !== -1) {
                insuredCoverDetails.forEach(function (insuredCoverage) {
                   if (["AM", "TD", "HC"].indexOf(insuredCoverage.getCoverCode()) !== -1) {
                       self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES], claimTypesList);
                       self.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setSumInsured(insuredCoverage.getSumInsured());
                   }
                    if (["AD", "DI"].indexOf(insuredCoverage.getCoverCode()) !== -1) {
                        self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS], claimTypesList);
                    }
                });
            } else {
                self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES], claimTypesList);
                self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS], claimTypesList);
            }

        } else {
            // HKG, HGI check eligibility of claims types for selected insured
            if (product === "PAN" || product === "PAM") {
                claimTypesList = self.injector.get(PlanHelperService).getEligiblePAClaimTypesByInsured(product, claimTypesList, selectedPerson, self.claim)
            } else if (product === "PAD") {
                self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES], claimTypesList);
                self.showEligibleClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS], claimTypesList);
            }

        }

        // check previous claim types availability
        for (let i = 0; i < claimTypesList.length; i++) {
            if (prevClaimTypesList[i]['show'] && !claimTypesList[i]['show']) {
                this.claimTypeItemHelperService.deleteClaimType(i);
            }
        }

        this.transactionInfo.setClaimTypeItemList(claimTypesList);
    }

    showEligibleClaimType(claimTypeCode: string, claimTypeItems: ClaimTypeItem[]) {
        for (const claimTypeItem of claimTypeItems) {
            if (claimTypeItem.claimTypeCode === claimTypeCode) {
                claimTypeItem.show = true;
                break;
            }
        }
    }

    getInsuredCoverDetails(selectedPerson: InsuredPerson): CoverDetails[] {
        let insuredCoverDetails = selectedPerson.getCoverDetailsList();
        // if insured selected insured is coming from p400
        if (!UtilitiesService.isNullOrUndefined(this.selectedPerson.getRiskNumber())) {
            this.claim.setRiskNumber(selectedPerson.getRiskNumber());
            this.claim.setPremiumClass(selectedPerson.getPremiumClass());
            this.claim.setPlanCode(selectedPerson.getPlanCode());
        } else {
            // if insured is not from p400, default to risk one coverage details
            if (this.claim.getRiskNumber() !== 0) {
                this.claim.setRiskNumber(1);
            }
            let defaultIns = this.transactionInfo.getInsuredPersonsList().filter(x => x.getRiskNumber() === 1);
            if (!UtilitiesService.isNullOrUndefined(defaultIns) && defaultIns.length > 0) {
                insuredCoverDetails = defaultIns[0].getCoverDetailsList();
                this.claim.setPlanCode(defaultIns[0].getPlanCode());
            }
        }

        return insuredCoverDetails;
    }

    getDefaultClaimTypes(claimTypesList: ClaimTypeItem[]): ClaimTypeItem[] {
        claimTypesList.forEach(function (claimType) {
           if (claimType.show && claimType.claimTypeCode.indexOf('OTHERS') === -1) {
               claimType.show = false;
           }
        });
        return claimTypesList;
    }

    initialDatePicker() {
        let that = this;
        $('#birth').daterangepicker(
            {
                format: 'yyyy-mm-dd',
                fontAwesome: true,
                singleDatePicker: true,
                minYear: 1900,
                maxYear: 3000,
                showDropdowns: true,
                minDate: '01/01/1900',
            }
        ).on('change', function (ev) {
            //for otherPersonContactForm - DOB
            that.otherInsuredPersonForm.patchValue({
                otherPersonDOB: ev.currentTarget.value
            });

        });
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    updateInsuredPerson() {
        if (this.validateForm()) {
            let claimantName: string = this.claim.getClaimantName();
            let claimantIdentifier = this.claim.getClaimantIdentifier();
            this.currentClaimantName = claimantName;
            for (var i =0 ; i < this.insuredPersonList.length ; i++) {
                let insuredIdentifier = UtilitiesService.createClaimantIdentifier(this.insuredPersonList[i]);
                let insuredName = this.insuredPersonList[i].getInsuredName();
                if (claimantName == insuredName && claimantIdentifier == insuredIdentifier) {
                    this.insuredPersonList[i].setInsuredPersonIdType(this.otherInsuredPersonForm.get('otherPersonIdType').value);
                    this.insuredPersonList[i].setInsuredPersonNationalIdNumber(this.otherInsuredPersonForm.get('otherPersonHKID').value);
                    this.insuredPersonList[i].setInsuredName(this.otherInsuredPersonForm.get('otherPersonName').value);
                    this.insuredPersonList[i].setInsuredDateOfBirth(super.getValueInComponent('otherPersonDOB'));
                    
                    this.selectedPerson = this.insuredPersonList[i];
                    this.choosedPersonId = i;
                    break;
                }
            }
            this.transactionInfo.setInsuredPersonsList(this.insuredPersonList);
            return true;
        } else {
            return false;
        }

    }

    addInsuredPerson() {

        if (this.validateForm()) {

            //save other insured person to transactionInfo
            let insuredPersonInfoList: InsuredPerson[] =  this.insuredPersonList;
            let otherInsuredPerson: InsuredPerson = new InsuredPerson();
            otherInsuredPerson.setInsuredPersonIdType(this.otherInsuredPersonForm.get('otherPersonIdType').value);
            otherInsuredPerson.setInsuredPersonNationalIdNumber(this.otherInsuredPersonForm.get('otherPersonHKID').value);
            otherInsuredPerson.setInsuredName(this.otherInsuredPersonForm.get('otherPersonName').value);
            otherInsuredPerson.setInsuredDateOfBirth(super.getValueInComponent('otherPersonDOB'));
            insuredPersonInfoList.push(otherInsuredPerson);

            this.transactionInfo.setInsuredPersonsList(insuredPersonInfoList);
            this.showMoreContent = false;
            this.otherInsuredPersonForm.reset();

            //pre select new insured person
            let insuredPersonLength: number = insuredPersonInfoList.length - 1;
            this.choosePerson(insuredPersonLength, otherInsuredPerson);
        }

        if (this.transactionInfo.isTravelProduct() && 
         !UtilitiesService.isNullOrUndefined(this.transactionInfo.getInsuredPersonsList()) &&
            this.transactionInfo.getInsuredPersonsList().length > 1) {
            this.showAdditionalClaimantMessage = true;
        } else {
            this.showAdditionalClaimantMessage = false;
        }

    }

    back() {
        this.sideMenuService.emitProgress(-1);
        this.router.navigate(["/claimform/confirmTravelPeriod"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.otherInsuredPersonForm);
    }

    showChangeInsuredModal() {
        this.changeInsuredModalRef = this.modalService.open(this.changeInsuredModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    removeSelectedClaimTypes() {

        // remove selected claim type details - with multi claimant entry
        let selectedClaimTypeList = this.claim.getSelectedClaimTypesList();
        if (selectedClaimTypeList.length > 0 && this.transactionInfo.isTravelProduct()) {
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_TDEL') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(0);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_MED_VISIT') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(4);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_MED_STY') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(5);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_OTH') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(15);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_CAN') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(6);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_MED_DTH') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(13);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_LIAB') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(14);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_CAN_RROUT') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(10);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_BAG_LOST') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(1);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_BAG_DMG') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(2);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_BDEL') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(3);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_TDEL_MC') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(12);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_CAN_CRTL') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(8);
            }
            if (selectedClaimTypeList.indexOf('CLAIM_TYPE_MSEVNT') !== -1) {
                this.claimTypeItemHelperService.deleteClaimType(11);
            }
        }

        // reset claimant list
        let claimantList: Claimant[] = [];
        this.claim.setClaimantList(claimantList);

        // populate claimant list and navigate to select claim
        this.goToSelectClaim(this.selectedPerson);

        this.changeInsuredModalRef.close();

    }

    closeChangeInsuredModal()  {

        let insuredPersonList = this.insuredPersonList;
        let claimantName = this.claim.getClaimantName();
        let claimantIdentifier = this.claim.getClaimantIdentifier();
        let self = this;
        if (!UtilitiesService.isNullOrUndefined(claimantName)) {
            insuredPersonList.forEach(function (insuredPerson, index) {
                let insuredPersonIdentifer = UtilitiesService.createClaimantIdentifier(insuredPerson);
                if (insuredPerson.getInsuredName() == claimantName && claimantIdentifier == insuredPersonIdentifer) {
                   self.selectedPerson = insuredPerson;
                   self.choosedPersonId = index;
                }
            });
        }

        this.changeInsuredModalRef.close();
    }

    //START - Google Analytics
    pushGASelectInsuredView() {
       let authMethod: string;
       if(this.isNotNullOrUndefinedStr(this.transactionInfo.getName()) &&
        !this.transactionInfo.isAccidentProduct()){
           authMethod = "Name";
       } else if (this.isNotNullOrUndefined(this.transactionInfo.getDateOfBirth())) {
           authMethod = "DOB";
       }

       if(this.transactionInfo.isDomesticHelperProduct()) {
           let channel: string = "HKSI";
           if (this.claim.getCountry() == "HGI") {
               channel = "QGI";
           } else if (this.claim.getIsManulife())  {
               channel = "Manulife";
           }

           (<any>window).dataLayer.push({
               'pageStep': 'Select Helper',
               'AuthMethod': authMethod,
               'Channel': channel,     // QGI, HKSI or Manulife
               'vPath': '/select-helper',
               'event': 'vpageview',
               'ecommerce': {
                   'checkout': {
                       'actionField': {'step': 3},	//Step3-Select Helper
                   }
               }
           });

       } else {
           (<any>window).dataLayer.push({
               'pageStep': 'Select Insured Person',
               'AuthMethod': authMethod,
               'vPath': '/select-insured',
               'event': 'vpageview',
               'ecommerce': {
                   'checkout': {
                       'actionField': {'step': 3},	//Step3-SelectInsured
                   }
               }
           });
       }
    }

    pushGASelectInsuredOnClick() {
        (<any>window).dataLayer.push({'event': 'addinsured'});
    }
    //END - Google Analytics

    scrollIntoView(el){
        el.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
    open(content, name) {
        this.modalService.open(content, {size: 'lg'});
    }

}
