export class Version {
    
   public static version = 'uat2 02152025033624';
    
    public static getUrlVersion(): string {
        return '?version=' + this.getVersion();
    }

    public static getVersion(): string {
        return this.version;
    }
}